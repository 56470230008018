<template>
  <div>
    <div class="text-center sticky py-3">
      <b-button
          class="mr-3"
          :variant="provisionChanges.length > 0 ? 'secondary' : 'info'"
          size="lg"
          v-b-modal.save
          :disabled="istSpeichernButtonDeaktiviert"
      >
        Änderungen speichern
      </b-button>
      <b-modal id="save" size="lg" title="Änderungen speichern" cancel-title="Abbrechen" @ok="save">
        <b-alert variant="warning" :show="zeigeNiedrigeProvisionWarnung">
          Du hast eine sehr niedrige Provision eingegeben. Bist du dir sicher, dass diese Provision korrekt ist?
        </b-alert>
        <b-alert variant="warning" :show="zeigeHoheProvisionWarnung">
          Du hast eine sehr hohe Provision eingegeben. Bist du dir sicher, dass diese Provision korrekt ist?
        </b-alert>
        <p class="my-4">
          <!-- stacked table als Alternative zur responsive table -->
          <!-- <b-table stacked="lg" :items="changedItems" :fields="modalFields"> -->
          <b-table responsive :items="changedItems" :fields="modalFields">
            <template #cell(centProvisionNeu)="row">
            <span :class="{
                  'provision': true,
                  'warn': row.item.centProvisionNeu >= (0.9 * row.item.centBasisprovision)
                    || row.item.centProvisionNeu <= 1000
                  }">
              {{ row.item.centProvisionNeu | centsToEuros }}
            </span>
            </template>
            <template #cell(centProvisionAlt)="row">
              {{ row.item.centProvisionAlt | centsToEuros }}
            </template>
            <template #cell(centBasisprovision)="row">
              {{ row.item.centBasisprovision | centsToEuros }}
            </template>
          </b-table>
        </p>
      </b-modal>
      <b-button
          class="mr-3"
          :variant="provisionChanges.length > 0 ? 'secondary' : 'info'"
          size="lg"
          v-b-modal.reset
          :disabled="istResetButtonDeaktiviert"
      >Änderungen zurücksetzen
      </b-button>
      <b-modal id="reset" size="lg" title="Änderungen zurücksetzen" cancel-title="Abbrechen" @ok="reset">
        <b-alert variant="warning" show>Willst du wirklich alle nicht gespeicherten Änderungen löschen?</b-alert>
      </b-modal>
    </div>
    <b-alert variant="danger" :show="localAlertState.provisionsWarnung.fehlerhafteProvisionsIndizes.length > 0">
      {{ localAlertState.provisionsWarnung.fehlerhafteProvisionsIndizes.length }}
      <span v-if="localAlertState.provisionsWarnung.fehlerhafteProvisionsIndizes.length > 1">Provisionen sind</span>
      <span v-else>Provision ist</span>
      größer als die Basisprovision.
    </b-alert>
    <b-button @click="showHideDescription">{{ descriptionTitle }}</b-button>
    <div v-if="descriptionVisible">
      1. Trage auf dieser Seite die Basisprovisionen ein, die Du deinen Mitarbeitern auszahlst<br/>
      2. Trage bitte bei folgenden Geschäftfällen die Provision, die du für Media Receiver auszahlst, mit ein!
      Komplettwechsel, Wechsel ohne DSL im Bestand und Wechsel mit DSL im Bestand, Triple Play<br/>
      3. Wir zeigen dir deine Basisprovision als Hilfe an. Beachte: Die Basisprovision ist ohne Media Receiver!<br/>
      4. Sämtliche Änderungen von dir sind sofort nach abspeichern live! Bitte prüfe Änderungen daher genau<br/>
      5. Unter dem Link "Provisionshistorie" kannst du dir alle deine gemachten Änderungen ansehen
    </div>
    <span class="text-right">
      <h5>
        <b-link :to="{ name: 'provisionslog'}">Provisionshistorie</b-link>
      </h5>
    </span>
    <common-table
        :fields="fields"
        :busy="viewstate.loading"
        :sortBy="sortBy"
        :dataProvider="holeProvisionen"
        :usePagination="usePagination"
        :useClientSorting="true"
        :useSticky="false"
        :distance-to-top="4"
        :other-filters-size="6"
        show-empty>
      <template
          v-slot:beforeSearchFilter="filterMutations">
        <span v-if="showPartnerFilter()">
          <vertriebspartner-filter style="float: left; width: 30%" :filterMutations="filterMutations"
                                   :beforeChange="beforePartnerChange"/>
        </span>
        <geschaeftsfall-filter style="float: left; width: 30%" :filterMutations="filterMutations"/>
        <kategorie-filter style="float: left; width: 30%" :filterMutations="filterMutations"/>
      </template>
      <template v-slot:empty>
        <h4 class="py-3 text-center">
          Es sind derzeit keine Provision gepflegt.
        </h4>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(centProvision)="row">
        <b-form-input :key="row.item.key"
                      size="sm"
                      @input.native="checkProvisionDelayed"
                      type="number"
                      :id="row.item.key"
                      :value="(getProvisionById(row.item.key) != null ? getProvisionById(row.item.key) : row.item.centProvision)/100"
                      :max="row.item.centBasisprovision/100"
                      :min="0"
                      :class="{ 'input-provision': true, changed: changedProvisionById(row.item.key) !== undefined }"
                      :data-provision-id="row.item.key"
        /> &euro;
      </template>
      <template #cell(centBasisprovision)="row">
        {{ row.item.centBasisprovision | centsToEuros }}
      </template>
    </common-table>
  </div>
</template>

<script>
import CommonTableMixin from '@pso/vue-shared/mixins/componentRelation/commonTable'
import {mapActions, mapGetters, mapState} from 'vuex'
import GeschaeftsfallFilter from '../../components/common/GeschaeftsfallFilter'
import KategorieFilter from '../../components/common/KategorieFilter'
import VertriebspartnerFilter from '../../components/common/VertriebspartnerFilter'
import provision from '../../api/kpi-energie-api/provision'

export default {
  name: 'Provisionstabelle',
  mixins: [CommonTableMixin],
  components: {GeschaeftsfallFilter, KategorieFilter, VertriebspartnerFilter},
  computed: {
    ...mapState({
      viewstate: state => state.viewstate.viewstate,
      provisionChanges: state => state.provision.provisionChanges,
      provision: state => state.provision.provision,
      filters: state => state.provision.filters
    }),
    ...mapGetters({
      changedProvisionById: 'provision/changedProvisionById'
    }),
    changedItems() {
      let filtered = Object.keys(this.provisionChanges)
          .filter(x => this.provision[x] && this.provisionChanges[x] !== undefined)
          .map(x => {
            return {
              id: x,
              geschäftsfall: this.provision[x].geschäftsfall,
              centProvisionNeu: this.provisionChanges[x],
              centProvisionAlt: this.provision[x].centProvision,
              tarif: this.provision[x].tarif,
              centBasisprovision: this.provision[x].centBasisprovision
            }
          })
      return filtered
    },
    zeigeNiedrigeProvisionWarnung() {
      return this.changedItems
          .findIndex(a => a.centProvisionNeu <= 1000) > -1
    },
    zeigeHoheProvisionWarnung() {
      return this.changedItems
          .findIndex(a => a.centProvisionNeu >= (0.9 * a.centBasisprovision)) > -1
    },
    istSpeichernButtonDeaktiviert() {
      return this.localAlertState.provisionsWarnung.fehlerhafteProvisionsIndizes.length > 0
          || this.changedItems.length === 0
    },
    istResetButtonDeaktiviert() {
      return this.changedItems.length === 0 && this.localAlertState.provisionsWarnung.fehlerhafteProvisionsIndizes.length === 0
    }
  },
  data() {
    return {
      fields: [
        {key: 'geschäftsfall', label: 'Geschäftsfall', sortable: true},
        {key: 'tarif', label: 'Tarif', sortable: true},
        {key: 'centProvision', label: 'Provision', sortable: true},
        {key: 'centBasisprovision', label: 'Basisprovision', sortable: true}
      ],
      modalFields: [
        {key: 'id', label: 'Id', sortable: true},
        {key: 'geschäftsfall', label: 'Geschäftsfall', sortable: true},
        {key: 'tarif', label: 'Tarif', sortable: true},
        {key: 'centProvisionNeu', label: 'Neu', sortable: true},
        {key: 'centProvisionAlt', label: 'Alt', sortable: true},
        {key: 'centBasisprovision', label: 'Basisprovision', sortable: true}
      ],
      sortBy: 'centProvision',
      usePagination: false,
      descriptionTitle: 'Beschreibung anzeigen',
      descriptionVisible: false,
      provisionInputa: null,
      debounceId: null,
      istVerwaltungFirma: false,
      localAlertState: {
        provisionsWarnung: {
          fehlerhafteProvisionsIndizes: []
        },
      }
    }
  },
  methods: {
    ...mapActions({
      setProvisionlist: 'provision/setProvisionlist',
      setUpdates: 'provision/setUpdates',
      saveChanges: 'provision/saveChanges',
      setState: 'viewstate/setState',
      setFilters: 'provision/setFilters',
      resetChanges: 'provision/resetChanges',
    }),
    save() {
      this.saveChanges(this)
    },
    reset() {
      this.localAlertState.provisionsWarnung.fehlerhafteProvisionsIndizes = []
      this.resetChanges(this)
    },
    showHideDescription() {
      this.descriptionVisible = !this.descriptionVisible
      this.descriptionTitle = this.descriptionVisible ? 'Beschreibung ausblenden' : 'Beschreibung anzeigen'
    },
    checkProvisionDelayed(evt) {
      if (this.debounceId) {
        clearTimeout(this.debounceId)
        this.debounceId = null
      }
      this.debounceId = setTimeout(() => this.checkProvision(evt), 200)
    },
    showPartnerFilter() {
      return this.istVerwaltungFirma
    },
    checkProvision(evt) {
      evt.target.classList.remove('error')

      let value = evt.target.value * 100
      let provisionId = evt.target.dataset.provisionId
      let provision = this.provision[provisionId]

      if (value > provision.centBasisprovision) {
        this.addProvisionWarnung(provisionId)
        evt.target.classList.add('error')
      } else {
        this.removeProvisionWarnung(provisionId)
      }
      const newValue = value
      this.setUpdates({id: provisionId, newValue})
    },
    addProvisionWarnung(provisionsId) {
      if (!this.localAlertState.provisionsWarnung.fehlerhafteProvisionsIndizes.includes(provisionsId)) {
        this.localAlertState.provisionsWarnung.fehlerhafteProvisionsIndizes.push(provisionsId)
      }
    },
    removeProvisionWarnung(provisionsId) {
      let index = this.localAlertState.provisionsWarnung.fehlerhafteProvisionsIndizes.indexOf(provisionsId)
      if (index > -1) {
        this.localAlertState.provisionsWarnung.fehlerhafteProvisionsIndizes.splice(index, 1)
      }
    },
    getProvisionById(id) {
      return this.changedProvisionById(id) ?? null
    },
    holeProvisionen(filters) {
      return this.setProvisionlist({filters, caller: this})
    },
    showBeforeunload(event) {
      if (this.changedItems.length > 0) {
        event.returnValue = ' '
        return ' '
      }
    },
    beforePartnerChange() {
      if (this.changedItems.length > 0) {
        if (confirm('Sie haben ungespeicherte Änderungen. Möchten Sie diese Seite verlassen?') === true) {
          this.reset()
          return true;
        }
        return false;
      }
      return true;
    }
  },
  created() {
    window.addEventListener('beforeunload', this.showBeforeunload)
    provision.istVerwaltungFirma().then(result => {
      this.istVerwaltungFirma = result.data
    })
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.showBeforeunload)
  }
}
</script>

<style scoped lang="scss">
.input-provision {
  max-width: 80px;
  display: inline;

  &.changed {
    outline: blueviolet 2px solid
  }

  &.error {
    outline: red 2px solid
  }
}

span.provision {
  &.warn {
    color: white;
    background-color: #ff00009e;
    padding: 0.2rem;
    border-radius: 5px;
  }
}
</style>
